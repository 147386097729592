import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import styles from './SideBar.module.scss';
import * as PropTypes from 'prop-types';

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

function SideBarLink(props) {
  return null;
}

SideBarLink.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.shape({ padding: PropTypes.any, width: PropTypes.any }),
  className: PropTypes.any,
  to: PropTypes.any,
  children: PropTypes.node,
};
const SubMenu = ({ item, isHidden, subnav, setSubnav }) => {
  const onClickItem = () => {
    if (item.subNav) {
      setSubnav(!subnav);
      return item.subNav;
    }
  };
  return (
    <>
      <NavLink
        to={item.path ? item.path : '#'}
        onClick={onClickItem}
        style={{ padding: isHidden ? 27 : 20, width: isHidden ? 80 : '100%' }}
        className={styles.link}
        activeClassName={item.path ? styles.active : null}
      >
        <div className={styles.sideBarItemDiv}>
          {item.icon}
          {!isHidden && <SidebarLabel>{item.title}</SidebarLabel>}
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </NavLink>
      {subnav &&
        item.subNav &&
        item.subNav.map((item, index) => {
          return (
            <NavLink
              to={item.path}
              key={index}
              className={styles.dropDownLink}
              activeClassName={styles.active}
              style={{
                padding: isHidden ? 27 : 20,
                width: isHidden ? 80 : '100%',
              }}
            >
              {item.icon}
              {!isHidden && <SidebarLabel>{item.title}</SidebarLabel>}
            </NavLink>
          );
        })}
    </>
  );
};

export default SubMenu;
