import { LANGUAGE_ABBREVIATIONS } from '../consts/languages';

export const formatLanguage = (language) => {
  switch (language) {
    case LANGUAGE_ABBREVIATIONS.ENGLISH:
      return 'En';
    case LANGUAGE_ABBREVIATIONS.SPANISH:
      return 'Spa';
    case LANGUAGE_ABBREVIATIONS.FRENCH:
      return 'Fr';
    case LANGUAGE_ABBREVIATIONS.GERMAN:
      return 'Ger';
    case LANGUAGE_ABBREVIATIONS.ITALIAN:
      return 'Ita';
    case LANGUAGE_ABBREVIATIONS.MANDARIN:
      return 'Man';
    case LANGUAGE_ABBREVIATIONS.JAPANESE:
      return 'Jap';
    case LANGUAGE_ABBREVIATIONS.PORTUGUESE:
      return 'Por';
  }
};

export const formatLanguageLabel = (language) => {
  switch (language) {
    case LANGUAGE_ABBREVIATIONS.ENGLISH:
      return 'English';
    case LANGUAGE_ABBREVIATIONS.SPANISH:
      return 'Spanish';
    case LANGUAGE_ABBREVIATIONS.FRENCH:
      return 'French';
    case LANGUAGE_ABBREVIATIONS.GERMAN:
      return 'German';
    case LANGUAGE_ABBREVIATIONS.ITALIAN:
      return 'Italian';
    case LANGUAGE_ABBREVIATIONS.MANDARIN:
      return 'Mandarin';
    case LANGUAGE_ABBREVIATIONS.JAPANESE:
      return 'Japanese';
    case LANGUAGE_ABBREVIATIONS.PORTUGUESE:
      return 'Portuguese';
  }
};
