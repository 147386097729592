import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_RESPONDENT_SAGA,
  CREATE_RESPONDENT_SAGA,
  GET_RESPONDENT_TYPES_SAGA,
  SUBMIT_REQUEST_SAGA,
  RESET_PASS_SAGA,
} from './actionTypes';
import respondentService from '../../services/RespondentService';
import {
  setRespondentData,
  setRespondentTypes,
  setRespondent,
  setNewRespondentData,
  setResetPassModalOpen,
} from './actions';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';

export function* getRespondentSaga({ respondentId }) {
  try {
    const respondent = yield call(
      respondentService.getRespondent,
      respondentId
    );
    yield put(setRespondent(respondent));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* getRespondentTypesSaga() {
  try {
    const respondentTypes = yield call(respondentService.getRespondentTypes);
    yield put(setRespondentTypes(respondentTypes));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* createRespondentSaga({ payload }) {
  try {
    const respondentData = yield call(
      respondentService.createRespondent,
      payload.values
    );
    yield put(setRespondentData(respondentData));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.respondent.created'),
      })
    );
    yield payload.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* submitRequestSaga({ payload }) {
  try {
    const respondent = yield call(respondentService.submitRequest, payload);
    yield put(setRespondent(respondent));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* resetPassSagaFn({ payload }) {
  try {
    const newRespondentData = yield call(
      respondentService.resetPassword,
      payload
    );
    yield put(setNewRespondentData(newRespondentData));
    yield put(setResetPassModalOpen(true));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export default function* respondentSaga() {
  yield takeLatest(GET_RESPONDENT_TYPES_SAGA, getRespondentTypesSaga);
  yield takeLatest(CREATE_RESPONDENT_SAGA, createRespondentSaga);
  yield takeLatest(GET_RESPONDENT_SAGA, getRespondentSaga);
  yield takeLatest(SUBMIT_REQUEST_SAGA, submitRequestSaga);
  yield takeLatest(RESET_PASS_SAGA, resetPassSagaFn);
}
