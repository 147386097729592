import {
  CREATE_RECRUITER_SAGA,
  SET_RECRUITER,
  DELETE_RECRUITER_SAGA,
  EDIT_RECRUITER_SAGA,
  GET_RECRUITER_SAGA,
  ADD_PROJECTS,
  DELETE_PROJECT,
  SET_REFRESH,
  SET_OPEN_CREATE_RECRUITER_MODAL,
} from './actionTypes';

export function createRecruiterSaga(payload, ref) {
  return {
    type: CREATE_RECRUITER_SAGA,
    payload,
    ref,
  };
}

export function editRecruiterSaga(recruiterId, payload) {
  return {
    type: EDIT_RECRUITER_SAGA,
    recruiterId,
    payload,
  };
}

export function deleteRecruiterSaga(recruiterId) {
  return {
    type: DELETE_RECRUITER_SAGA,
    recruiterId,
  };
}

export function getRecruiterSaga(recruiterId) {
  return {
    type: GET_RECRUITER_SAGA,
    recruiterId,
  };
}

export function setRecruiter(payload) {
  return {
    type: SET_RECRUITER,
    payload,
  };
}

export function addProjectsSaga(payload) {
  return {
    type: ADD_PROJECTS,
    payload,
  };
}

export function deleteRecruitersProjectSaga(payload, ref) {
  return {
    type: DELETE_PROJECT,
    payload,
    ref,
  };
}

export function setRefresh(payload) {
  return {
    type: SET_REFRESH,
    payload,
  };
}

export function setOpenCreateRecruiterModal(payload) {
  return {
    type: SET_OPEN_CREATE_RECRUITER_MODAL,
    payload,
  };
}
