import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_RECRUITER: '/recruiters/:recruiterId',
  GET_RECRUITER_GRID: '/recruiters/grid/:page/:limit/',
  CREATE_RECRUITER: '/recruiters',
  GET_RECRUITERS: '/recruiters',
  EDIT_RECRUITER: '/recruiters/:recruiterId',
  DELETE_RECRUITER: '/recruiters/:recruiterId',
  ADD_PROJECTS: 'recruiters/add-projects/:recruiterId',
  DELETE_PROJECT: 'recruiters/delete-projects/:projectId/:recruiterId',
};

class RecruiterService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getRecruiter = (recruiterId) => {
    return this.httpService.request({
      url: ROUTES.GET_RECRUITER.replace(':recruiterId', recruiterId),
      method: HTTP_METHODS.GET,
    });
  };

  getAllRecruiter = () => {
    return this.httpService.request({
      url: ROUTES.GET_RECRUITERS,
      method: HTTP_METHODS.GET,
    });
  };

  getRecruiterGrid = (page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_RECRUITER_GRID.replace(':page', page).replace(
          ':limit',
          limit
        ) + (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  createRecruiter = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_RECRUITER,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  editRecruiter = (recruiterId, data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_RECRUITER.replace(':recruiterId', recruiterId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  deleteRecruiter = (recruiterId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_RECRUITER.replace(':recruiterId', recruiterId),
      method: HTTP_METHODS.DELETE,
    });
  };

  addProjects = (data, recruiterId) => {
    return this.httpService.request({
      url: ROUTES.ADD_PROJECTS.replace(':recruiterId', recruiterId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteProject = (projectId, recruiterId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_PROJECT.replace(':projectId', projectId).replace(
        ':recruiterId',
        recruiterId
      ),
      method: HTTP_METHODS.DELETE,
    });
  };
}

const recruiterService = new RecruiterService(httpService);

export default recruiterService;
