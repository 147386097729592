export const LANGUAGE_ABBREVIATIONS = {
  ENGLISH: 'en',
  SPANISH: 'es',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  MANDARIN: 'man',
  JAPANESE: 'ja',
  PORTUGUESE: 'por',
};

export const LANGUAGE_LABELS = {
  ENGLISH: 'English',
  SPANISH: 'Spanish',
  FRENCH: 'French',
  GERMAN: 'German',
  ITALIAN: 'Italian',
  MANDARIN: 'Mandarin',
  JAPANESE: 'Japanese',
  PORTUGUESE: 'Portuguese',
};
