import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_CLIENT_GRID: '/users/grid/:page/:limit/',
  GET_PROJECTS_BY_CLIENT_GRID: '/projects/by-user/:clientId/grid/:page/:limit',
  EDIT_CLIENT: '/users/:clientId',
  GET_CLIENT: '/users/:clientId',
  DELETE_CLIENT: '/users/:clientId',
  CREATE_CLIENT: '/users/',
  DELETE_PROJECT: '/users/delete-projects/:projectId/:userId',
  ADD_PROJECTS: '/users/add-projects/:userId',
};

class ClientService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getClientGrid = (page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_CLIENT_GRID.replace(':page', page).replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getProjectGridByClientId = (clientId, page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_PROJECTS_BY_CLIENT_GRID.replace(':clientId', clientId)
          .replace(':page', page)
          .replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  editClient = (clientId, data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_CLIENT.replace(':clientId', clientId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getClient = (clientId) => {
    return this.httpService.request({
      url: ROUTES.GET_CLIENT.replace(':clientId', clientId),
      method: HTTP_METHODS.GET,
    });
  };

  deleteClient = (clientId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_CLIENT.replace(':clientId', clientId),
      method: HTTP_METHODS.DELETE,
    });
  };

  createClient = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_CLIENT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteProject = (projectId, userId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_PROJECT.replace(':projectId', projectId).replace(
        ':userId',
        userId
      ),
      method: HTTP_METHODS.DELETE,
    });
  };

  addProjects = (data, userId) => {
    return this.httpService.request({
      url: ROUTES.ADD_PROJECTS.replace(':userId', userId),
      method: HTTP_METHODS.POST,
      data,
    });
  };
}

const clientService = new ClientService(httpService);

export default clientService;
