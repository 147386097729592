import { call, takeLatest, put } from 'redux-saga/effects';
import {
  setProjects,
  setProject,
  setIsProjectSubmitted,
  setSubmittedProject,
} from './actions';
import projectService from '../../services/ProjectService';
import {
  GET_PROJECTS_SAGA,
  DELETE_PROJECT_SAGA,
  GET_PROJECT_SAGA,
  CREATE_PROJECT_SAGA,
  CLOSE_PROJECT_SAGA,
} from './actionTypes';
import { push } from 'connected-react-router';
import { PROJECT_LIST } from 'routes';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';
import moment from 'moment';
import {
  transformTranslationArray,
  transformTranslationObject,
} from '../../utils/project';
import { PROJECT_STEP_SUBTYPES } from '../../consts/types';
import { setLoader } from '../loader/actions';

export function* getProjectsSaga() {
  try {
    const projects = yield call(projectService.getProjects);
    yield put(setProjects(projects));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* getProjectSaga({ projectId }) {
  try {
    const project = yield call(projectService.getProject, projectId);
    yield put(setProject(project));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteProjectSaga({ projectId }) {
  try {
    yield call(projectService.deleteProject, projectId);
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.project.deleted'),
      })
    );
    yield put(push(PROJECT_LIST));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* closeProjectSaga({ projectId }) {
  try {
    yield call(projectService.closeProject, projectId);
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.project.closed'),
      })
    );
    yield put(push(PROJECT_LIST));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* createProjectSagaFn({ project }) {
  try {
    yield put(setLoader(true));
    const data = {};
    let _project;
    data.project_number = project.project_number;
    data.project_name = project.project_name;
    data.project_type_id = project.project_type_id;
    data.project_start_date = moment(project.project_start_date).format(
      'YYYY-MM-DD'
    );
    data.project_end_date = moment(project.project_end_date).format(
      'YYYY-MM-DD'
    );
    data.selectedLanguages = project.selectedLanguages.map((language) => {
      return language.value;
    });

    data.number_of_journeys = project.number_of_journeys;

    data.countryIds = project.countries.map((country) => country.id);

    data.therapeuticCategories = project.therapeuticCategories.map(
      (category) => {
        return transformTranslationObject({
          id: category.id,
          value: category.label,
          translations: category.translations,
        });
      }
    );

    const conditions = project.conditions.map((condition) => {
      return transformTranslationObject({
        id: condition.id,
        value: condition.label,
        translations: condition.translations,
      });
    });
    data.conditions = conditions;

    const diagnosedConditions = project.diagnosedConditions.map((condition) => {
      return transformTranslationObject({
        id: condition.id,
        value: condition.label,
        translations: condition.translations,
      });
    });
    data.diagnosedConditions = diagnosedConditions;

    const typeOfRespondents = project.typeOfRespondents.map(
      (typeOfRespondent) => {
        return transformTranslationObject({
          id: typeOfRespondent.id,
          value: typeOfRespondent.label,
          translations: typeOfRespondent.translations,
        });
      }
    );
    data.typeOfRespondents = typeOfRespondents;

    const cares = project.cares.map((care) => {
      return transformTranslationObject({
        id: care.id,
        value: care.label,
        translations: care.translations,
      });
    });
    data.cares = cares;

    const mergedQuestions = [
      ...project.onboardingQuestions,
      ...project.closingQuestions,
    ];

    data.customQuestions = mergedQuestions.map(({ id, ...rest }) => ({
      ...rest,
      questionChoices: rest.questionChoices?.map(
        ({ id, ...choiceRest }) => choiceRest
      ),
    }));

    data.customQuestions = mergedQuestions.map(({ id, subtype, ...rest }) => {
      let transformedChoices;

      switch (subtype) {
        case PROJECT_STEP_SUBTYPES.CONDITION:
          transformedChoices = conditions;
          break;
        case PROJECT_STEP_SUBTYPES.ADDITIONAL_CONDITION:
          transformedChoices = diagnosedConditions;
          break;
        case PROJECT_STEP_SUBTYPES.TYPE_OF_RESPONDENT:
          transformedChoices = typeOfRespondents;
          break;
        case PROJECT_STEP_SUBTYPES.CARE:
          transformedChoices = cares;
          break;
        case PROJECT_STEP_SUBTYPES.COUNTRY:
          transformedChoices = project.countries.map((country) => {
            return {
              id: country.id,
              translations: {
                en: country.label,
              },
            };
          });
          break;
        case PROJECT_STEP_SUBTYPES.AGE:
        case PROJECT_STEP_SUBTYPES.AVATAR:
        case PROJECT_STEP_SUBTYPES.RELATIONSHIP:
        case PROJECT_STEP_SUBTYPES.GENDER:
          transformedChoices = rest.questionChoices.map((choice) => {
            return transformTranslationObject({
              id: choice.id,
              value: choice.label || choice.value,
              translations: choice.translations,
            });
          });
          break;
        default:
          transformedChoices = rest.questionChoices?.map(
            ({ id, ...choiceRest }) => {
              return (transformedChoices =
                transformTranslationObject(choiceRest));
            }
          );
      }

      return {
        ...rest,
        subtype,
        title: transformTranslationObject(rest.title),
        ...(Object.keys(rest.question).length > 0 && {
          question: transformTranslationObject(rest.question),
        }),
        questionChoices: transformedChoices,
      };
    });

    data.journey_name = transformTranslationObject(project.journey_name);
    data.journey_type = project.journey_type;
    data.clarifications = transformTranslationObject(project.clarifications);
    data.journey_start_date = project.journey_start_date;
    data.journey_end_date = project.journey_end_date;

    data.steps = project.journeySteps.map((item) => ({
      id: item.id,
      order: item.order,
      events: item.events
        .filter((event) => event.selected)
        .map((event) =>
          transformTranslationObject({
            id: event.id,
            value: event.value,
            translations: event.translations,
          })
        ),
      name: transformTranslationArray(item.nameTranslations),
      question: transformTranslationArray(item.questionTranslations),
    }));

    data.admin_id = project.admin_id;
    data.userIds = project.users.map((user) => user.id);
    data.recruiterIds = project.recruiters.map((recruiter) => recruiter.id);

    data.surveyUrl = project.surveyUrl;

    if (project.edit) {
      _project = yield call(projectService.updateProject, project.id, data);
    } else {
      _project = yield call(projectService.createProject, data);
    }

    yield put(
      setSubmittedProject({
        projectId: _project.id,
        isModalVisible: true,
      })
    );

    yield project.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
    yield put(setLoader(false));
  }
}

export default function* projectSaga() {
  yield takeLatest(GET_PROJECT_SAGA, getProjectSaga);
  yield takeLatest(DELETE_PROJECT_SAGA, deleteProjectSaga);
  yield takeLatest(CLOSE_PROJECT_SAGA, closeProjectSaga);
  yield takeLatest(GET_PROJECTS_SAGA, getProjectsSaga);
  yield takeLatest(CREATE_PROJECT_SAGA, createProjectSagaFn);
}
