import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRecruiter = (state) => state.recruiter || initialState;

const recruiterSelector = () =>
  createSelector(selectRecruiter, (state) => state.recruiter);

const refreshTableSelector = () =>
  createSelector(selectRecruiter, (state) => state.refreshed);

const createRecruiterModalOpenSelector = () =>
  createSelector(selectRecruiter, (state) => state.isCreateModalOpen);

export {
  recruiterSelector,
  refreshTableSelector,
  createRecruiterModalOpenSelector,
};
