import produce from 'immer';

import {
  SET_OPEN_CREATE_RECRUITER_MODAL,
  SET_RECRUITER,
  SET_REFRESH,
} from './actionTypes';

export const initialState = {
  recruiter: null,
  refreshed: false,
  isCreateModalOpen: false,
};

/* eslint-disable default-case */

const recruiterReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_RECRUITER:
        draft.recruiter = payload;
        break;

      case SET_REFRESH:
        draft.refreshed = payload;
        break;

      case SET_OPEN_CREATE_RECRUITER_MODAL:
        draft.isCreateModalOpen = payload;
        break;
    }
  });

export default recruiterReducer;
