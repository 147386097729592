export const CLIENT = '[Client]';

export const EDIT_CLIENT_SAGA = `${CLIENT} EDIT_CLIENT_SAGA`;
export const DELETE_CLIENT_SAGA = `${CLIENT} DELETE_CLIENT_SAGA`;
export const GET_CLIENT_SAGA = `${CLIENT} GET_CLIENT_SAGA`;
export const SET_CLIENT = `${CLIENT} SET_CLIENT`;
export const CREATE_CLIENT_SAGA = `${CLIENT} CREATE_CLIENT_SAGA`;
export const REMOVE_PROJECT_SAGA = `${CLIENT} REMOVE_PROJECT_SAGA`;
export const ADD_PROJECTS_SAGA = `${CLIENT} ADD_PROJECTS_SAGA`;
export const SET_CREATE_CLIENT_MODAL_OPEN = `${CLIENT} SET_CREATE_CLIENT_MODAL_OPEN`;
