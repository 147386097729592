import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSnackbar = (state) => state.snackbar || initialState;

const snackbarSelector = () =>
  createSelector(selectSnackbar, (state) => state.snackbar);
const sideBarStateSelector = () =>
  createSelector(selectSnackbar, (state) => state.sideBarState);

export { snackbarSelector, sideBarStateSelector };
