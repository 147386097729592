import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_RESPONDENT: '/respondents/:respondentId',
  DELETE_RESPONDENT: '/respondents/:respondentId',
  SUBMIT_REQUEST: '/respondents/request-review/:respondentId',
  RESET_PASS: '/respondents/regen-pass/:respondentId',
  GET_RESPONDENT_GRID: '/respondents/grid/:page/:limit/',
  GET_RESPONDENT_GRID_BY_PROJECT_ID:
    '/respondents/by-project/:projectId/grid/:page/:limit/',
  GET_RESPONDENT_TYPES: '/respondent-types/',
  CREATE_RESPONDENT: '/respondents',
  GET_ANALYTICS: '/respondents/analytics',
  EXPORT: '/respondents/export-all',
};

class RespondentService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getRespondent = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.GET_RESPONDENT.replace(':respondentId', respondentId),
      method: HTTP_METHODS.GET,
    });
  };

  submitRequest = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.SUBMIT_REQUEST.replace(':respondentId', respondentId),
      method: HTTP_METHODS.PUT,
    });
  };

  resetPassword = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.RESET_PASS.replace(':respondentId', respondentId),
      method: HTTP_METHODS.PUT,
    });
  };

  exportRespondents = () => {
    return this.httpService.request({
      url: ROUTES.EXPORT,
      method: HTTP_METHODS.GET,
      responseType: 'arraybuffer',
    });
  };

  getRespondentAnalytics = () => {
    return this.httpService.request({
      url: ROUTES.GET_ANALYTICS,
      method: HTTP_METHODS.GET,
    });
  };

  getRespondentGrid = (page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_RESPONDENT_GRID.replace(':page', page).replace(
          ':limit',
          limit
        ) + (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getRespondentGridByProjectId = (projectId, page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_RESPONDENT_GRID_BY_PROJECT_ID.replace(
          ':projectId',
          projectId
        )
          .replace(':page', page)
          .replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getRespondentTypes = () => {
    return this.httpService.request({
      url: ROUTES.GET_RESPONDENT_TYPES,
      method: HTTP_METHODS.GET,
    });
  };

  createRespondent = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_RESPONDENT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteRespondent = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_RESPONDENT.replace(':respondentId', respondentId),
      method: HTTP_METHODS.DELETE,
    });
  };
}

const respondentService = new RespondentService(httpService);

export default respondentService;
