export const PROJECT_STEP_TYPES = {
  DYNAMIC_FORM: 'dynamic-form',
  ONBOARDING_QUESTION: 'onboarding',
  REORDER_ONBOARDING_QUESTIONS: 'reorder-onboarding-questions',
  CREATE_JOURNEY: 'create-journey',
  JOURNEY_STEPS: 'journey-steps',
  TRANSLATE_JOURNEY_STEPS: 'translate-journey-steps',
  REORDER_JOURNEY_STEPS: 'reorder-journey-steps',
  JOURNEY_STEP_EVENTS: 'journey-step-events',
  CLOSING_QUESTIONS: 'closing',
  REORDER_CLOSING_QUESTIONS: 'reorder-closing-questions',
  ADD_SURVEY: 'add-survey',
};

export const PROJECT_STEP_SUBTYPES = {
  TYPE_OF_RESPONDENT: 'type_of_respondent',
  CONDITION: 'condition',
  COUNTRY: 'country',
  DATE_OF_BIRTH: 'date_of_birth',
  GENDER: 'gender',
  ADDITIONAL_CONDITION: 'additional_condition',
  RELATIONSHIP: 'relationship',
  CARE: 'care',
  AGE: 'age',
  AVATAR: 'avatar',
};
