export const PROJECT = '[Project]';

export const GET_PROJECTS_SAGA = `${PROJECT} GET_PROJECTS_SAGA`;
export const SET_PROJECTS = `${PROJECT} SET_PROJECTS`;
export const GET_PROJECT_SAGA = `${PROJECT} GET_PROJECT_SAGA`;
export const SET_PROJECT = `${PROJECT} SET_PROJECT`;
export const DELETE_PROJECT_SAGA = `${PROJECT} DELETE_PROJECT_SAGA`;
export const CLOSE_PROJECT_SAGA = `${PROJECT} CLOSE_PROJECT_SAGA`;
export const SET_PROJECT_ID = `${PROJECT} SET_PROJECT_ID`;
export const CREATE_PROJECT_SAGA = `${PROJECT} CREATE_PROJECT_SAGA`;
export const SET_SUBMITTED_PROJECT = `${PROJECT} SET_SUBMITTED_PROJECT`;
