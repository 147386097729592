import {
  ADD_PROJECTS_SAGA,
  CREATE_CLIENT_SAGA,
  DELETE_CLIENT_SAGA,
  EDIT_CLIENT_SAGA,
  GET_CLIENT_SAGA,
  SET_CLIENT,
  REMOVE_PROJECT_SAGA,
  SET_CREATE_CLIENT_MODAL_OPEN,
} from './actionTypes';

export function editClientSaga(clientId, payload) {
  return {
    type: EDIT_CLIENT_SAGA,
    clientId,
    payload,
  };
}

export function deleteClientSaga(clientId) {
  return {
    type: DELETE_CLIENT_SAGA,
    clientId,
  };
}

export function getClientSaga(clientId) {
  return {
    type: GET_CLIENT_SAGA,
    clientId,
  };
}

export function setClient(payload) {
  return {
    type: SET_CLIENT,
    payload,
  };
}

export function createClientSaga(payload) {
  return {
    type: CREATE_CLIENT_SAGA,
    payload,
  };
}

export function removeProjectSaga(projectId, userId, ref) {
  return {
    type: REMOVE_PROJECT_SAGA,
    projectId,
    userId,
    ref,
  };
}

export function addProjectsSaga(projectIds, userId, ref) {
  return {
    type: ADD_PROJECTS_SAGA,
    projectIds,
    userId,
    ref,
  };
}

export function setCreateClientModalOpen(payload) {
  return {
    type: SET_CREATE_CLIENT_MODAL_OPEN,
    payload,
  };
}
