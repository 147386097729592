import {
  GET_RESPONDENT_SAGA,
  SET_RESPONDENT,
  CREATE_RESPONDENT_SAGA,
  GET_RESPONDENT_TYPES_SAGA,
  SET_RESPONDENT_DATA,
  SET_RESPONDENT_TYPES,
  SUBMIT_REQUEST_SAGA,
  SET_NEW_RESPONDENT_DATA,
  RESET_PASS_SAGA,
  SET_RESET_PASS_MODAL_OPEN,
} from './actionTypes';

export function getRespondentSaga(respondentId) {
  return {
    type: GET_RESPONDENT_SAGA,
    respondentId,
  };
}

export function setRespondent(payload) {
  return {
    type: SET_RESPONDENT,
    payload,
  };
}

export function getRespondentTypesSaga() {
  return {
    type: GET_RESPONDENT_TYPES_SAGA,
  };
}

export function setRespondentTypes(payload) {
  return {
    type: SET_RESPONDENT_TYPES,
    payload,
  };
}

export function createRespondentSaga(payload) {
  return {
    type: CREATE_RESPONDENT_SAGA,
    payload,
  };
}

export function setRespondentData(payload) {
  return {
    type: SET_RESPONDENT_DATA,
    payload,
  };
}

export function setNewRespondentData(payload) {
  return {
    type: SET_NEW_RESPONDENT_DATA,
    payload,
  };
}

export function submitRequestSaga(payload) {
  return {
    type: SUBMIT_REQUEST_SAGA,
    payload,
  };
}

export function resetPassSaga(payload) {
  return {
    type: RESET_PASS_SAGA,
    payload,
  };
}

export function setResetPassModalOpen(payload) {
  return {
    type: SET_RESET_PASS_MODAL_OPEN,
    payload,
  };
}
