import { takeLatest, call, put } from 'redux-saga/effects';
import {
  DELETE_ADMIN_SAGA,
  EDIT_ADMIN_SAGA,
  GET_SINGLE_ADMIN_SAGA,
  MAIN_SEARCH_SAGA,
} from './actionTypes';
import { setSnackbar } from '../snackbar/actions';
import adminService from '../../services/AdminService';
import { setAdmin, setIsSearchActive, setSearchTerm } from './actions';
import { push } from 'connected-react-router';
import { ADMINS, DASHBOARD } from '../../routes';
import { $t } from '../../translations';

export function* getSingleAdminSaga({ payload }) {
  try {
    const admin = yield call(adminService.getAdmin, payload);
    yield put(setAdmin(admin));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteAdminSaga({ payload }) {
  try {
    yield call(adminService.deleteAdmin, payload);
    yield put(push(ADMINS));
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.admin.deleted'),
      })
    );
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* editAdminSaga({ adminId, payload }) {
  try {
    const admin = yield call(adminService.editAdmin, payload, adminId);
    yield put(setAdmin(admin));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.admin.edited'),
      })
    );
    yield put(push(ADMINS));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* mainSearchSagaFn({ payload }) {
  try {
    yield put(setSearchTerm(payload.term));
    if (payload.history.location.pathname !== '/home') {
      yield put(push(DASHBOARD));
    }
    yield put(setIsSearchActive(true));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export default function* adminSaga() {
  yield takeLatest(GET_SINGLE_ADMIN_SAGA, getSingleAdminSaga);
  yield takeLatest(DELETE_ADMIN_SAGA, deleteAdminSaga);
  yield takeLatest(EDIT_ADMIN_SAGA, editAdminSaga);
  yield takeLatest(MAIN_SEARCH_SAGA, mainSearchSagaFn);
}
