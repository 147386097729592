import React, { useEffect, useState } from 'react';
import styles from './SideBar.module.scss';
import logoText from '../../assets/images/logo/logo-text.svg';
import logo from '../../assets/images/logo/logo.svg';
import { ReactComponent as Home } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as Respondents } from '../../assets/icons/Respondents.svg';
import { ReactComponent as Clients } from '../../assets/icons/Clients.svg';
import { ReactComponent as Recruiters } from '../../assets/icons/Recruiters.svg';
import { ReactComponent as Projects } from '../../assets/icons/Projects.svg';
import { ReactComponent as Transcripts } from '../../assets/icons/Transcripts.svg';
import { ReactComponent as Journeys } from '../../assets/icons/Journeys.svg';
import { ReactComponent as Data } from '../../assets/icons/Data.svg';
import useWindowDimensions from '../../utils/dimensionHook';
import { HiOutlineMenu } from 'react-icons/hi';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SubMenu from './SubMenu';

import {
  BiCategory,
  CgUserList,
  GiHealthNormal,
  GoListOrdered,
  SiIconify,
  RiAdminLine,
  MdLibraryBooks,
  IoMedical,
  MdHealthAndSafety,
} from 'react-icons/all';
import * as RiIcons from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { userJwtDataSelector } from '../../store/auth/selectors';
import { setSidebarState } from '../../store/snackbar/actions';

function SideBar({ onSideBarOpen, user }) {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [isHidden, setIsHidden] = React.useState(false);
  const userJwtData = useSelector(userJwtDataSelector());
  const dispatch = useDispatch();

  const dataCollapsibleItems = [
    {
      title: t('side_bar.data_items.icons'),
      path: '/data/icons',
      icon: <SiIconify color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.therapeutic_category'),
      path: '/data/therapeutic-categories',
      icon: <BiCategory color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.diseases_conditions'),
      path: '/data/conditions',
      icon: <GiHealthNormal color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.types_of_respondents'),
      path: '/data/respondent-types',
      icon: <CgUserList color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.project_types'),
      path: '/data/project-types',
      icon: <GoListOrdered color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.journey_types'),
      path: '/data/journey-types',
      icon: <MdLibraryBooks color={'#89A0A7'} />,
    },
    {
      title: t('side_bar.data_items.cares'),
      path: '/data/cares',
      icon: <IoMedical color={'#89A0A7'} />,
    },
    // {
    //   title: t('side_bar.data_items.healthcare_professions'),
    //   path: '/data/healthcare-professions',
    //   icon: <MdHealthAndSafety color={'#89A0A7'} />,
    // },
    // {
    //   title: 'Countries',
    //   path: '/data/countries',
    //   icon: <GiEarthAmerica color={'#89A0A7'} />,
    // },
  ];

  const SidebarNav = styled.nav`
    background: $color-primary-dark-blue;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
  `;

  const SidebarWrap = styled.div`
    width: 100%;
  `;
  const [subnav, setSubnav] = useState(false);

  useEffect(() => {
    if (width < 1200) {
      setIsHidden(true);
      dispatch(setSidebarState(true));
    } else {
      setIsHidden(false);
      dispatch(setSidebarState(false));
    }
  }, [width]);

  const SIDE_BAR_ITEMS = [
    {
      title: t('side_bar.item.home'),
      path: '/home',
      icon: <Home className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.admins'),
      path: '/admins',
      icon: (
        <RiAdminLine
          className={styles.iconStyle}
          style={{ height: '24px', width: '24px' }}
        />
      ),
      canView: ['superadmin'],
    },
    {
      title: t('side_bar.item.respondents'),
      path: '/respondents',
      icon: <Respondents className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.clients'),
      path: '/clients',
      icon: <Clients className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.recruiters'),
      path: '/recruiters',
      icon: <Recruiters className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.projects'),
      path: '/projects',
      icon: <Projects className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.transcripts'),
      path: '/transcripts',
      icon: <Transcripts className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.journey_steps'),
      path: '/journeys',
      icon: <Journeys className={styles.iconStyle} />,
      canView: ['superadmin', 'admin'],
    },
    {
      title: t('side_bar.item.data'),
      icon: <Data className={styles.iconStyle} />,
      canView: ['superadmin'],
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: dataCollapsibleItems,
    },
  ];

  const checkSideBarItemVisibility = (item) => {
    return item.canView.some((role) => role === userJwtData?.role);
  };

  const hideMenu = (e) => {
    setIsHidden(!isHidden);
    onSideBarOpen(!isHidden);
    dispatch(setSidebarState(!isHidden));
  };

  const menuClasses = classNames(styles.menuIcon, {
    [styles.hiddenMenuIcon]: isHidden,
  });

  return (
    <div
      className={styles.sideBar}
      style={{ width: isHidden ? '80px' : '250px' }}
    >
      <SidebarNav
        sidebar={true}
        style={{
          width: isHidden ? '100px' : '250px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <SidebarWrap>
          {!isHidden ? (
            <div className={styles.logoWrapper}>
              <HiOutlineMenu onClick={hideMenu} className={menuClasses} />
              <img
                src={isHidden ? logo : logoText}
                alt="logo"
                className={styles.image}
              />
            </div>
          ) : (
            <div className={styles.hiddenLogoWrapper}>
              <img src={logo} alt="logo" className={styles.image} />
              <HiOutlineMenu onClick={hideMenu} className={menuClasses} />
            </div>
          )}
          {SIDE_BAR_ITEMS.map((item, index) => {
            return (
              userJwtData &&
              checkSideBarItemVisibility(item) && (
                <SubMenu
                  isHidden={isHidden}
                  subnav={subnav}
                  setSubnav={(value) => setSubnav(value)}
                  item={item}
                  key={index.toString()}
                />
              )
            );
          })}
        </SidebarWrap>
      </SidebarNav>
    </div>
  );
}

export default SideBar;
