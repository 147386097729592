import produce from 'immer';

import { SET_SIDEBAR_SATE, SET_SNACKBAR } from './actionTypes';

export const initialState = {
  snackbar: {},
  sideBarState: false,
};

/* eslint-disable default-case */

const snackbarReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_SNACKBAR:
        draft.snackbar = payload;
        break;
      case SET_SIDEBAR_SATE:
        draft.sideBarState = payload;
        break;
    }
  });

export default snackbarReducer;
