import { takeLatest, call, put } from 'redux-saga/effects';
import {
  ADD_PROJECTS_SAGA,
  CREATE_CLIENT_SAGA,
  DELETE_CLIENT_SAGA,
  EDIT_CLIENT_SAGA,
  GET_CLIENT_SAGA,
  REMOVE_PROJECT_SAGA,
} from './actionTypes';
import { setClient, setCreateClientModalOpen } from './actions';
import { push } from 'connected-react-router';
import { CLIENT_LIST } from 'routes';
import clientService from '../../services/ClientService';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';
import { setProjectId } from '../project/actions';

export function* editClientSaga({ clientId, payload }) {
  try {
    const client = yield call(clientService.editClient, clientId, payload);
    yield put(setClient(client));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.client.edited'),
      })
    );
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* getClientSaga({ clientId }) {
  try {
    const client = yield call(clientService.getClient, clientId);
    yield put(setClient(client));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteClientSaga({ clientId }) {
  try {
    yield call(clientService.deleteClient, clientId);
    yield put(push(CLIENT_LIST));
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.client.deleted'),
      })
    );
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* createClientSaga({ payload }) {
  try {
    const client = yield call(clientService.createClient, payload);
    yield put(setClient(client));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.client.created'),
      })
    );

    yield put(setCreateClientModalOpen(false));
    yield payload.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* removeProjectSaga({ projectId, userId, ref }) {
  try {
    yield call(clientService.deleteProject, projectId, userId);
    yield put(setProjectId(projectId));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.project.removed'),
      })
    );
    yield ref.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* addProjectsSaga({ projectIds, userId, ref }) {
  try {
    yield call(clientService.addProjects, projectIds, userId);
    yield put(setProjectId(projectIds));
    yield ref.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export default function* clientSaga() {
  yield takeLatest(EDIT_CLIENT_SAGA, editClientSaga);
  yield takeLatest(GET_CLIENT_SAGA, getClientSaga);
  yield takeLatest(DELETE_CLIENT_SAGA, deleteClientSaga);
  yield takeLatest(CREATE_CLIENT_SAGA, createClientSaga);
  yield takeLatest(REMOVE_PROJECT_SAGA, removeProjectSaga);
  yield takeLatest(ADD_PROJECTS_SAGA, addProjectsSaga);
}
