import produce from 'immer';

import {
  SET_NEW_RESPONDENT_DATA,
  SET_RESET_PASS_MODAL_OPEN,
  SET_RESPONDENT,
} from './actionTypes';

import { SET_RESPONDENT_DATA, SET_RESPONDENT_TYPES } from './actionTypes';

export const initialState = {
  singleRespondent: null,
  respondentTypes: null,
  respondentData: null,
  newRespondentData: null,
  isResetPassModalOpen: false,
};

/* eslint-disable default-case */
const respondentReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_RESPONDENT:
        draft.singleRespondent = payload;
        break;
      case SET_RESPONDENT_TYPES:
        draft.respondentTypes = payload;
        break;
      case SET_RESPONDENT_DATA:
        draft.respondentData = payload;
        break;
      case SET_NEW_RESPONDENT_DATA:
        draft.newRespondentData = payload;
        break;
      case SET_RESET_PASS_MODAL_OPEN:
        draft.isResetPassModalOpen = payload;
        break;
    }
  });

export default respondentReducer;
