export const RESPONDENT = '[Respondent]';

export const GET_RESPONDENT_SAGA = `${RESPONDENT} GET_RESPONDENT_SAGA`;
export const SET_RESPONDENT = `${RESPONDENT} SET_RESPONDENT`;
export const GET_RESPONDENT_TYPES_SAGA = `${RESPONDENT} GET_RESPONDENT_TYPES_SAGA`;
export const SET_RESPONDENT_TYPES = `${RESPONDENT} SET_RESPONDENT_TYPES`;
export const CREATE_RESPONDENT_SAGA = `${RESPONDENT} CREATE_RESPONDENT_SAGA`;
export const SET_RESPONDENT_DATA = `${RESPONDENT} SET_RESPONDENT_DATA`;
export const SET_NEW_RESPONDENT_DATA = `${RESPONDENT} SET_NEW_RESPONDENT_DATA`;
export const SUBMIT_REQUEST_SAGA = `${RESPONDENT} SUBMIT_REQUEST_SAGA`;
export const RESET_PASS_SAGA = `${RESPONDENT} RESET_PASS_SAGA`;
export const SET_RESET_PASS_MODAL_OPEN = `${RESPONDENT} SET_RESET_PASS_MODAL_OPEN`;
