import { SET_SIDEBAR_SATE, SET_SNACKBAR } from './actionTypes';

export function setSnackbar(payload) {
  return {
    type: SET_SNACKBAR,
    payload,
  };
}
export function setSidebarState(payload) {
  return {
    type: SET_SIDEBAR_SATE,
    payload,
  };
}
