import produce from 'immer';

import { SET_CLIENT, SET_CREATE_CLIENT_MODAL_OPEN } from './actionTypes';

export const initialState = {
  client: null,
  isCreateModalOpen: false,
};

/* eslint-disable default-case */

const clientReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_CLIENT:
        draft.client = payload;
        break;
      case SET_CREATE_CLIENT_MODAL_OPEN:
        draft.isCreateModalOpen = payload;
        break;
    }
  });

export default clientReducer;
