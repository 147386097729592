import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  DELETE_RECRUITER_SAGA,
  EDIT_RECRUITER_SAGA,
  GET_RECRUITER_SAGA,
  CREATE_RECRUITER_SAGA,
  ADD_PROJECTS,
  DELETE_PROJECT,
} from './actionTypes';
import {
  setOpenCreateRecruiterModal,
  setRecruiter,
  setRefresh,
} from './actions';
import recruiterService from '../../services/RecruiterService';
import { push } from 'connected-react-router';
import { RECRUITER_LIST } from 'routes';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';
import { refreshTableSelector } from './selectors';

export function* createRecruiterSaga({ payload, ref }) {
  try {
    const recruiter = yield call(recruiterService.createRecruiter, payload);
    yield put(setRecruiter(recruiter));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.recruiter.created'),
      })
    );
    yield put(setOpenCreateRecruiterModal(false));
    yield ref.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* editRecruiterSaga({ recruiterId, payload }) {
  try {
    const recruiter = yield call(
      recruiterService.editRecruiter,
      recruiterId,
      payload
    );
    yield put(setRecruiter(recruiter));
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.recruiter.edited'),
      })
    );
    yield put(push(RECRUITER_LIST));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* getRecruiterSaga({ recruiterId }) {
  try {
    const recruiter = yield call(recruiterService.getRecruiter, recruiterId);
    yield put(setRecruiter(recruiter));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteRecruiterSaga({ recruiterId }) {
  try {
    yield call(recruiterService.deleteRecruiter, recruiterId);
    yield put(push(RECRUITER_LIST));
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.recruiter.deleted'),
      })
    );
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* addProjectsToRecruiterSaga({ payload }) {
  try {
    const isRefreshed = yield select(refreshTableSelector());
    let projectsIds = [];
    payload.val.projectIds.forEach((item) => {
      projectsIds.push(item.id);
    });
    yield call(
      recruiterService.addProjects,
      { projectIds: projectsIds },
      payload.recruiterId
    );
    yield put(setRefresh(!isRefreshed));
    yield payload.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteProjectToRecruiterSaga({ payload, ref }) {
  try {
    yield call(
      recruiterService.deleteProject,
      payload.projectId,
      payload.recruiterId
    );
    yield ref.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export default function* recruiterSaga() {
  yield takeLatest(CREATE_RECRUITER_SAGA, createRecruiterSaga);
  yield takeLatest(EDIT_RECRUITER_SAGA, editRecruiterSaga);
  yield takeLatest(GET_RECRUITER_SAGA, getRecruiterSaga);
  yield takeLatest(DELETE_RECRUITER_SAGA, deleteRecruiterSaga);
  yield takeLatest(ADD_PROJECTS, addProjectsToRecruiterSaga);
  yield takeLatest(DELETE_PROJECT, deleteProjectToRecruiterSaga);
}
