export const ADMIN = '[Admin]';

export const GET_SINGLE_ADMIN_SAGA = `${ADMIN} GET_SINGLE_ADMIN_SAGA`;
export const SET_REFRESH = `${ADMIN} SET_REFRESH`;
export const SET_ADMIN = `${ADMIN} SET_ADMIN`;
export const DELETE_ADMIN_SAGA = `${ADMIN} DELETE_ADMIN_SAGA`;
export const EDIT_ADMIN_SAGA = `${ADMIN} EDIT_ADMIN_SAGA`;
export const SET_SEARCH_TERM = `${ADMIN} SET_SEARCH_TERM`;
export const MAIN_SEARCH_SAGA = `${ADMIN} MAIN_SEARCH_SAGA`;
export const SET_IS_SEARCH_ACTIVE = `${ADMIN} SET_IS_SEARCH_ACTIVE`;
