import { call, takeLatest, put } from 'redux-saga/effects';
import { setStates } from './actions';
import { GET_STATES_SAGA } from './actionTypes';
import stateService from '../../services/StateService';

export function* getStatesSaga() {
  try {
    const states = yield call(stateService.getStates);
    yield put(setStates(states));
  } catch (error) {
    console.error(error);
  }
}

export default function* stateSaga() {
  yield takeLatest(GET_STATES_SAGA, getStatesSaga);
}
