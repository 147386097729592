import { formatLanguageLabel } from './formatString';
import { LANGUAGE_ABBREVIATIONS, LANGUAGE_LABELS } from '../consts/languages';
import { PROJECT_STEP_SUBTYPES, PROJECT_STEP_TYPES } from '../consts/types';
import projectService from '../services/ProjectService';
import { setSnackbar } from '../store/snackbar/actions';
import { setSubmittedProject } from '../store/project/actions';
import { v4 as uuidv4 } from 'uuid';

export const transformTranslationObject = (object) => {
  const { id, order, value, translations } = object;

  const result = {};
  if (id !== undefined) result.id = id;
  if (order !== undefined) result.order = order;
  if (value) {
    result.translations = {
      en: value,
    };
  } else {
    result.translations = {};
  }

  translations &&
    translations.forEach((translation) => {
      result.translations[translation.language] = translation.value;
    });
  return result;
};

export const transformTranslationArray = (array) => {
  return {
    translations: array.reduce((acc, { language, value }) => {
      acc[language] = value;
      return acc;
    }, {}),
  };
};

export const transformTranslationFromObjectToArray = (object) => {
  const languageKeys = [
    LANGUAGE_ABBREVIATIONS.ENGLISH,
    LANGUAGE_ABBREVIATIONS.SPANISH,
    LANGUAGE_ABBREVIATIONS.FRENCH,
    LANGUAGE_ABBREVIATIONS.GERMAN,
    LANGUAGE_ABBREVIATIONS.ITALIAN,
    LANGUAGE_ABBREVIATIONS.MANDARIN,
    LANGUAGE_ABBREVIATIONS.JAPANESE,
    LANGUAGE_ABBREVIATIONS.PORTUGUESE,
  ];

  return Object.entries(object)
    .filter(([key, value]) => languageKeys.includes(key) && value)
    .map(([key, value]) => ({
      language: key,
      value: value,
    }));
};

const getLanguageId = (language) => {
  switch (language) {
    case LANGUAGE_ABBREVIATIONS.ENGLISH:
      return 2;
    case LANGUAGE_ABBREVIATIONS.SPANISH:
      return 3;
    case LANGUAGE_ABBREVIATIONS.FRENCH:
      return 4;
    case LANGUAGE_ABBREVIATIONS.GERMAN:
      return 5;
    case LANGUAGE_ABBREVIATIONS.ITALIAN:
      return 6;
    case LANGUAGE_ABBREVIATIONS.MANDARIN:
      return 7;
    case LANGUAGE_ABBREVIATIONS.JAPANESE:
      return 8;
    case LANGUAGE_ABBREVIATIONS.PORTUGUESE:
      return 9;
  }
};
export const getLanguageLabel = (language) => {
  switch (language) {
    case LANGUAGE_ABBREVIATIONS.ENGLISH:
      return LANGUAGE_LABELS.ENGLISH;
    case LANGUAGE_ABBREVIATIONS.SPANISH:
      return LANGUAGE_LABELS.SPANISH;
    case LANGUAGE_ABBREVIATIONS.FRENCH:
      return LANGUAGE_LABELS.FRENCH;
    case LANGUAGE_ABBREVIATIONS.GERMAN:
      return LANGUAGE_LABELS.GERMAN;
    case LANGUAGE_ABBREVIATIONS.ITALIAN:
      return LANGUAGE_LABELS.ITALIAN;
    case LANGUAGE_ABBREVIATIONS.MANDARIN:
      return LANGUAGE_LABELS.MANDARIN;
    case LANGUAGE_ABBREVIATIONS.JAPANESE:
      return LANGUAGE_LABELS.JAPANESE;
    case LANGUAGE_ABBREVIATIONS.PORTUGUESE:
      return LANGUAGE_LABELS.PORTUGUESE;
  }
};

export const formatProject = (project) => {
  const journeyNameTranslations = project.journey.translates.find(
    (translate) => translate.field === 'name'
  );
  const clarificationsTranslations = project.journey.translates.find(
    (translate) => translate.field === 'clarifications'
  );

  const customQuestions = (customQuestions) => {
    return customQuestions.map((question) => {
      const customQuestionTitleTranslations =
        question.translates.find((translate) => translate.field === 'title') ||
        '';
      const customQuestionQuestionTranslations =
        question.translates.find(
          (translate) => translate.field === 'question'
        ) || '';

      return {
        id: question.id,
        type: question.type,
        subtype: question.subtype,
        default: question.subtype,
        order: question.order,
        title: {
          ...(customQuestionTitleTranslations && {
            value:
              customQuestionTitleTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
          }),
          ...(customQuestionTitleTranslations && {
            translations: transformTranslationFromObjectToArray(
              customQuestionTitleTranslations
            ),
          }),
        },
        question: {
          ...(customQuestionQuestionTranslations && {
            value:
              customQuestionQuestionTranslations[
                LANGUAGE_ABBREVIATIONS.ENGLISH
              ],
          }),
          ...(customQuestionQuestionTranslations && {
            translations: transformTranslationFromObjectToArray(
              customQuestionQuestionTranslations
            ),
          }),
        },
        questionType: question.questionType,
        questionChoices: question.customQuestionChoices.map((choice) => {
          const choiceTranslations = choice.translates[0];

          return {
            value: choiceTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
            ...(choiceTranslations && {
              translations:
                transformTranslationFromObjectToArray(choiceTranslations),
              id: choice.external_id || uuidv4(),
            }),
          };
        }),
        ...(question.subtype === PROJECT_STEP_SUBTYPES.RELATIONSHIP && {
          group: 1,
        }),
        ...((question.subtype === PROJECT_STEP_SUBTYPES.CARE ||
          question.subtype === PROJECT_STEP_SUBTYPES.AGE) && { parent: 1 }),
      };
    });
  };

  return {
    edit: project.edit,
    id: project.id,
    project_number: project.project_number,
    project_name: project.name,
    project_type_id: project.project_type_id,
    project_start_date: new Date(project.start_date),
    project_end_date: new Date(project.end_date),
    selectedLanguages: project.selectedLanguages.split(',').map((language) => ({
      id: getLanguageId(language),
      value: language,
      label: formatLanguageLabel(language),
    })),

    number_of_journeys: project.number_of_journeys,

    countries: project.countries.map((country) => {
      return {
        id: country.id,
        value: country.id,
        label: country.name,
      };
    }),

    therapeuticCategories: project.projectTherapeuticCategories.map(
      (category) => {
        const categoryTranslations = category.translates[0];

        return {
          id: category.therapeutic_category_id,
          value: category.therapeutic_category_id,
          label: categoryTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
          translations:
            transformTranslationFromObjectToArray(categoryTranslations),
        };
      }
    ),

    conditions: project.projectConditions.map((condition) => {
      const conditionTranslations = condition.translates[0];

      return {
        id: condition.condition_id,
        value: condition.condition_id,
        label: conditionTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
        translations: transformTranslationFromObjectToArray(
          conditionTranslations
        ),
      };
    }),

    diagnosedConditions: project.projectDiagnosedConditions.map((condition) => {
      const conditionTranslations = condition.translates[0];

      return {
        id: condition.condition_id,
        value: condition.condition_id,
        label: conditionTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
        translations: transformTranslationFromObjectToArray(
          conditionTranslations
        ),
      };
    }),
    typeOfRespondents: project.projectRespondentTypes.map((type) => {
      const typeTranslations = type.translates[0];

      return {
        id: type.respondent_type_id,
        value: type.respondent_type_id,
        label: typeTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
        translations: transformTranslationFromObjectToArray(typeTranslations),
      };
    }),

    cares: project.projectCares.map((care) => {
      const careTranslations = care.translates[0];

      return {
        id: care.care_id,
        value: care.care_id,
        label: careTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
        translations: transformTranslationFromObjectToArray(careTranslations),
      };
    }),

    onboardingQuestions: customQuestions(
      project.customQuestions.filter(
        (question) => question.type === PROJECT_STEP_TYPES.ONBOARDING_QUESTION
      )
    ),

    journey_name: {
      value: journeyNameTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
      translations: transformTranslationFromObjectToArray(
        journeyNameTranslations
      ),
    },

    journey_type: project.journey.journey_type_id,

    clarifications: {
      value: clarificationsTranslations[LANGUAGE_ABBREVIATIONS.ENGLISH],
      translations: transformTranslationFromObjectToArray(
        clarificationsTranslations
      ),
    },

    journey_start_date: new Date(project.journey.start_date),
    journey_end_date: new Date(project.journey.end_date),

    journeySteps: project.stepJourneys.map((stepJourney) => {
      const nameTranslations =
        stepJourney.translates.find(
          (translate) => translate.field === 'name'
        ) || '';
      const questionTranslations =
        stepJourney.translates.find(
          (translate) => translate.field === 'question'
        ) || '';

      return {
        id: stepJourney.step_id,
        nameTranslations:
          transformTranslationFromObjectToArray(nameTranslations),
        questionTranslations:
          transformTranslationFromObjectToArray(questionTranslations),
        order: stepJourney.order,
        events: stepJourney.stepJourneyEvents.map((stepJourneyEvent) => {
          const eventTranslations =
            stepJourneyEvent.translates.find(
              (translate) => translate.field === 'name'
            ) || '';

          return {
            ...stepJourneyEvent,
            id: stepJourneyEvent.event_id,
            translations:
              transformTranslationFromObjectToArray(eventTranslations),
            selected: true,
          };
        }),
      };
    }),

    closingQuestions: customQuestions(
      project.customQuestions.filter(
        (question) => question.type === PROJECT_STEP_TYPES.CLOSING_QUESTIONS
      )
    ),

    skipClosingQuestionsReorder:
      !project.customQuestions.filter(
        (question) => question.type === PROJECT_STEP_TYPES.CLOSING_QUESTIONS
      ).length > 0,

    admin_id: project.admin_id,
    users: project.users.map((user) => {
      return {
        id: user.id,
        value: user.id,
        label: user.email,
      };
    }),
    recruiters: project.recruiters.map((recruiter) => {
      return {
        id: recruiter.id,
        value: recruiter.id,
        label: recruiter.email,
      };
    }),

    surveyUrl: project.surveyUrl,
  };
};

export const downloadProjectWithTranslations = async ({
  projectId,
  dispatch,
}) => {
  try {
    const res = await projectService.downloadTranslations(projectId);
    if (res) {
      const link = document.createElement('a');
      link.href = res;
      link.setAttribute('download', `project-${projectId}-translations.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    dispatch(setSubmittedProject(null));
  } catch (e) {
    console.log({ e });
    dispatch(
      setSnackbar({
        open: true,
        severity: 'error',
        message: e.data.message,
      })
    );
  }
};
