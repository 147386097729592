import { fork } from 'redux-saga/effects';

import AuthSagas from './auth/sagas';
import ProfileSagas from './profile/sagas';
import RespondentSagas from './respondent/sagas';
import ProjectSagas from './project/sagas';
import StateSagas from './state/sagas';
import RecruiterSagas from './recruiter/sagas';
import ClientSagas from './client/sagas';
import AdminSagas from './admin/sagas';

export default function* rootSaga() {
  yield fork(AuthSagas);
  yield fork(ProfileSagas);
  yield fork(RespondentSagas);
  yield fork(ProjectSagas);
  yield fork(StateSagas);
  yield fork(RecruiterSagas);
  yield fork(ClientSagas);
  yield fork(AdminSagas);
}
