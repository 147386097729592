export const RECRUITER = '[Recruiter]';

export const CREATE_RECRUITER_SAGA = `${RECRUITER} CREATE_RECRUITER_SAGA`;
export const EDIT_RECRUITER_SAGA = `${RECRUITER} EDIT_RECRUITER_SAGA`;
export const DELETE_RECRUITER_SAGA = `${RECRUITER} DELETE_RECRUITER_SAGA`;
export const GET_RECRUITER_SAGA = `${RECRUITER} GET_RECRUITER_SAGA`;
export const SET_RECRUITER = `${RECRUITER} SET_RECRUITER`;
export const ADD_PROJECTS = `${RECRUITER} ADD_PROJECTS`;
export const DELETE_PROJECT = `${RECRUITER} DELETE_PROJECT`;
export const SET_REFRESH = `${RECRUITER} SET_REFRESH`;
export const SET_OPEN_CREATE_RECRUITER_MODAL = `${RECRUITER} SET_OPEN_CREATE_RECRUITER_MODAL`;
