import produce from 'immer';

import {
  SET_ADMIN,
  SET_IS_SEARCH_ACTIVE,
  SET_REFRESH,
  SET_SEARCH_TERM,
} from './actionTypes';

export const initialState = {
  singleAdmin: null,
  refreshed: false,
  searchTerm: null,
  isSearchActive: false,
};

/* eslint-disable default-case */

const adminReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_ADMIN:
        draft.singleAdmin = payload;
        break;

      case SET_REFRESH:
        draft.refreshed = payload;
        break;

      case SET_SEARCH_TERM:
        draft.searchTerm = payload;
        break;

      case SET_IS_SEARCH_ACTIVE:
        draft.isSearchActive = payload;
        break;
    }
  });

export default adminReducer;
