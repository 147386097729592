export const AUTH = '[Auth]';

export const LOGIN_REQUEST = `${AUTH} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${AUTH} LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${AUTH} LOGIN_ERROR`;
export const FETCH_AUTHENTICATED_USER_REQUEST = `${AUTH} FETCH_AUTHENTICATED_USER_REQUEST`;
export const FETCH_AUTHENTICATED_USER_SUCCESS = `${AUTH} FETCH_AUTHENTICATED_USER_SUCCESS`;
export const LOGOUT_REQUEST = `${AUTH} LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${AUTH} LOGOUT_SUCCESS`;
export const SET_TOKEN = `${AUTH} SET_TOKEN`;
export const SESSION_EXPIRED = `${AUTH} SESSION_EXPIRED`;
export const FORGOT_PASSWORD_REQUEST = `${AUTH} FORGOT_PASSWORD_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${AUTH} FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_ERROR = `${AUTH} FORGOT_PASSWORD_ERROR`;
export const REGISTER_REQUEST = `${AUTH} REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${AUTH} REGISTER_SUCCESS`;
export const REGISTER_ERROR = `${AUTH} REGISTER_ERROR`;
export const RESET_PASSWORD_REQUEST = `${AUTH} RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${AUTH} RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${AUTH} RESET_PASSWORD_ERROR`;
export const SOCIAL_AUTH_REQUEST = `${AUTH} SOCIAL_AUTH_REQUEST`;
export const SOCIAL_AUTH_SUCCESS = `${AUTH} SOCIAL_AUTH_SUCCESS`;
export const SOCIAL_AUTH_ERROR = `${AUTH} SOCIAL_AUTH_ERROR`;
export const SET_LOGIN_ERROR = `${AUTH} SET_LOGIN_ERROR`;
export const SET_USER_JWT_DATA = `${AUTH} SET_USER_JWT_DATA`;
