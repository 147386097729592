import produce from 'immer';

import {
  SET_PROJECTS,
  SET_PROJECT,
  SET_PROJECT_ID,
  SET_SUBMITTED_PROJECT,
} from './actionTypes';

export const initialState = {
  projects: null,
  project: null,
  projectId: null,
  submittedProject: null,
};

/* eslint-disable default-case */
const projectReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_PROJECTS:
        draft.projects = payload;
        break;
      case SET_PROJECT:
        draft.project = payload;
        break;
      case SET_PROJECT_ID:
        draft.projectId = payload;
        break;
      case SET_SUBMITTED_PROJECT:
        draft.submittedProject = payload;
        break;
    }
  });

export default projectReducer;
