import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_STATES: '/states',
};

class StateService {
  constructor(httpService) {
    this.httpService = httpService;
  }
  getStates = () => {
    return this.httpService.request({
      url: ROUTES.GET_STATES,
      method: HTTP_METHODS.GET,
    });
  };
}

const stateService = new StateService(httpService);

export default stateService;
