import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess,
  loginSuccess,
  loginError,
  setToken,
  setLoginError,
  setUserJwtData,
} from './actions';
import { startAction, stopAction } from '../loading/actions';
import {
  LOGIN_REQUEST,
  FETCH_AUTHENTICATED_USER_REQUEST,
  LOGOUT_REQUEST,
} from './actionTypes';
import { DASHBOARD, LOGIN } from 'routes';
import authService from 'services/AuthService';
import { HTTP_STATUS_CODES } from 'consts';
import { removeItem } from '../../utils/localStorage';

export function* authorize({ type, email, password }) {
  try {
    yield put(startAction(type));
    const res = yield call(authService.login, { email, password });
    yield put(loginSuccess());
    yield put(setToken('token', res.token));
    yield put(push(DASHBOARD));
  } catch (error) {
    if (error && error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(stopAction(type));
  }
}

export function* fetchUser({ type }) {
  yield put(startAction(type));
  try {
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    const token = yield call(authService.getAccessToken);
    if (token) {
      const userData = yield call(authService.decodeJwt, token);
      yield put(setUserJwtData(userData));
    }
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(stopAction(type));
  }
}

export function* logout() {
  try {
    yield put(logoutSuccess());
    yield call(removeItem, 'token');
    yield call(authService.destroySession);
    yield put(push(LOGIN));
  } catch (error) {
    //
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(LOGOUT_REQUEST, logout);
}
