import { GET_STATES_SAGA, SET_STATES } from './actionTypes';

export function getStatesSaga() {
  return {
    type: GET_STATES_SAGA,
  };
}

export function setStates(payload) {
  return {
    type: SET_STATES,
    payload,
  };
}
