import {
  DELETE_ADMIN_SAGA,
  EDIT_ADMIN_SAGA,
  GET_SINGLE_ADMIN_SAGA,
  MAIN_SEARCH_SAGA,
  SET_ADMIN,
  SET_IS_SEARCH_ACTIVE,
  SET_REFRESH,
  SET_SEARCH_TERM,
} from './actionTypes';

export function getSingleAdminSaga(payload) {
  return {
    type: GET_SINGLE_ADMIN_SAGA,
    payload,
  };
}

export function setRefresh(payload) {
  return {
    type: SET_REFRESH,
    payload,
  };
}

export function setAdmin(payload) {
  return {
    type: SET_ADMIN,
    payload,
  };
}

export function deleteAdminSaga(payload) {
  return {
    type: DELETE_ADMIN_SAGA,
    payload,
  };
}

export function setSearchTerm(payload) {
  return {
    type: SET_SEARCH_TERM,
    payload,
  };
}

export function mainSearchSaga(payload) {
  return {
    type: MAIN_SEARCH_SAGA,
    payload,
  };
}

export function setIsSearchActive(payload) {
  return {
    type: SET_IS_SEARCH_ACTIVE,
    payload,
  };
}

export function editAdminSaga(adminId, payload) {
  return {
    type: EDIT_ADMIN_SAGA,
    adminId,
    payload,
  };
}
