import produce from 'immer';
import { SET_STATES } from './actionTypes';

export const initialState = {
  states: null,
};

/* eslint-disable default-case */
const stateReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_STATES:
        draft.states = payload;
        break;
    }
  });

export default stateReducer;
